<template>
    <div>
        <div class="banner-area pagenotfound">
            <div class="full-screen">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 mx-auto text-center">
                            <h1>404</h1>
                            <img src="../images/svg/404-error-page-not-found-with-people-connecting-a-plug-animate.svg" alt="Page not found" class="img-fluid">
                            <div class="info">
                                <h3 class="mb-2">Oops! Page not found.</h3>
                                <p>Try going back to the previous page or <br>
                                    <a @click="sendClick('/')" title="Go back home" class="my-3">Go back home
                                        <i class="icofont icofont-simple-right ml-1" aria-hidden=”true”></i>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import analytics from '../analytics.js';

export default {
    name: 'oops',
    mounted() {
        analytics.sendPageview();
    },
    methods: {
        sendClick(hash) {
            this.$router.push(hash);
        }
    }
}
</script>

<style>
.pagenotfound h1 {
    color: #7180ba;
    font-size: 6rem;
    position: absolute;
    width: 100%;
    right: 0.25rem;
}
.pagenotfound .info {
    position: relative;
    bottom: 3rem;
}
.pagenotfound .info h2 {
    font-size: 1rem;
}
.pagenotfound .img-fluid {
    max-width: 510px;
}
@media (max-width: 575.98px) {
    .pagenotfound .info h3 {
        font-size: 20px;
    }
}
</style>
