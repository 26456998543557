<template>
    <div>
        <div class="page-banner-area" id="page-banner">
            <div class="overlay overlay-sub"></div>
            <div class="container">
                <div class="row justify-content-center" data-aos="zoom-out" data-aos-duration="1500">
                    <div class="col-lg-10 m-auto text-center col-sm-12 col-md-12">
                        <div class="banner-content">
                            <h1 class="text-white">
                                Big problems require driven engineers. Let's create solutions together.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <video loop muted playsinline autobuffer autoplay
                poster="../images/bg-masthead.jpg" class="video-bkgd">
                <source src="media/nodes.mov" type="video/mp4">
            </video>
        </div>
        <section class="section-padding bg-white">
            <div class="container">
                <div class="row justify-content-center" data-aos="fade-in" data-aos-delay="200" data-aos-duration="1500">
                    <div class="col-lg-12">
                        <h2 class="mb-4">Join our team</h2>
                    </div>
                    <div class="col-lg-10 col-md-12 col-sm-12 col-md-8 mb-xl-0 mb-lg-5 mx-auto">
                        <p>At Enlighten, our team’s unwavering work ethic and our celebration of extraordinary ideas have helped us thrive. All members of our team, employees and management alike, seek to learn from our peers, share our skills, and collaborate to tackle challenging projects. It is the singular voice and united contributions of our team that help us exceed customer expectations and deliver profound solutions. We know that our employees are essential to our company’s success, so we seek to take care of you as much as you take care of us. We offer accommodations in and out of the office that make working for us a positive, fulfilling experience. Does that sound good? Come join our team! </p>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 order-lg-12" data-aos="fade-in" data-aos-delay="400" data-aos-duration="1500">
                        <img src="../images/svg/coworking-animate.svg" alt="Work hard" class="img-fluid">
                    </div>
                    <div class="col-lg-6 order-lg-1" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
                        <h3 class="mb-4">Work hard</h3>
                        <p>
                            It is a collaborative environment that has consistently taken our company and employees to the next level, so our top priority is fostering a collegial company culture where the opinions of our team are heard and valued. This dynamic has resulted in cutting-edge comprehensive solutions for our customers and company. We value innovation, and employees are encouraged to propose projects for further development and company funding. Many of our customers capabilities have been brought to life as the result of our idea sharing events.</p>
                        <p>
                            Management and employees alike harness the driving force of hard work to push our operations forward. We work as a team, push each other to develop award-winning solutions, and celebrate as a company as we grow and reach success. 
                        </p>                   
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6" data-aos="fade-in" data-aos-delay="400" data-aos-duration="1500">
                        <img src="../images/svg/celebration-animate.svg" alt="Play hard" class="img-fluid">
                    </div>
                    <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
                        <h3 class="mb-4">Play hard</h3>
                        <p>
                            At Enlighten, while hard work is at the forefront of our successes, we know that a healthy work-life balance decreases the stress and increases the motivation of our employees. We prioritize the well-being of our employees, and we offer a variety of accommodations both in and out of the office that make your life easier.
                        </p>
                        <p>
                            The majority of our work is conducted within the Baltimore/Washington Metro area as well as onsite in our customer spaces. Due to the nature of our work, we are often required to meet our customers in person and work directly alongside them to fulfill the mission. When possible, Enlighten supports hybrid work arrangements, enabling flexible execution. In the office, we provide customizable workspaces and areas for our employees to take a mental break. You can enjoy our lounge areas or our break room (stocked with free snacks and drinks).
                        </p>
                        <p>
                            Enlighten recognizes that sometimes life outside of work can throw challenges our way. We offer flexible schedules and telecommuting options to support our employees. We also use a variety of technologies to make sure employees are connected no matter where they are or what they need.
                        </p>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-lg-10 col-md-12 col-sm-12 mx-auto" data-aos="fade-in" data-aos-delay="400" data-aos-duration="1500">
                        <h3 class="mb-4">We’ll take care of the rest</h3>
                        <p>As you work hard and play hard, let us take care of the rest. Enlighten offers a comprehensive compensation program with benefit plans and offerings designed to provide you and your family with access to quality, affordable healthcare and programs that assist in support, security, and well-being. Benefits available to our employees include health, dental, vision, life, disability, supplemental benefits, company contributions under our 401K plan, PTO and holidays, educational resources, 24/7 employee assistance, and additional perks.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 mx-auto">
                        <div id="accordion">
                            <div class="list-group-item">
                            <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <h4>
                                    For Your Health
                                </h4>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    <h5 class="my-3">Medical, Dental, and Vision</h5>
                                    <i class="icofont icofont-medical-sign-alt" aria-hidden=”true”></i>
                                    <p class="mb-0">
                                        Find the plan that works for you! Enlighten offers <b>100% company-paid employee premium</b> Medical, Dental and Vision options. Medical plans available using the Anthem Blue Cross provider network and including prescription benefits living in the contiguous U.S. Additional plans available within select areas. 
                                    </p>
                                </div>
                            </div>
                            </div>
                            <div class="list-group-item">
                            <div class="card-header collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h4>
                                    For Your Future</h4>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div class="card-body">
                                    <h5 class="my-3">Savings and Retirement (401K)</h5>
                                    <i class="icofont icofont-money-bag" aria-hidden=”true”></i>
                                    <p>
                                        Employees are eligible for Enlighten's 401(k) plan, eligible <b>10% total</b> benefit (8% employer match and 2% employer contribution program).
                                    </p>
                                    <h5 class="my-3">Life/AD&amp;D/STD/LTD</h5>
                                    <i class="icofont icofont-bill-alt" aria-hidden=”true”></i>
                                    <p class="mb-0">
                                        Enlighten provides all full-time employees with life insurance and Accidental Death and Dismemberment (AD&amp;D) 1x base salary up to a maximum of $500,000. Short Term Disability (STD), Long Term Disability (LTD), Accidental Injury, Critical Illness insurance, and more are also available.
                                    </p>
                                </div>
                            </div>
                            </div>
                            <div class="list-group-item">
                            <div class="card-header collapsed" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h4>
                                    For Your Growth
                                </h4>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                <div class="card-body">
                                    <h5 class="my-3">Tuition, Training, and Student Loan Assistance</h5>
                                    <i class="icofont icofont-hat" aria-hidden=”true”></i>
                                    <p class="mb-0">
                                        The bank of knowledge never runs out. Looking to cash in? We support continued education and training for all our employees. We offer free extended learning resources that cover a variety of subjects for both professional and personal use, and additional benefits that assist with student loan payments and tuition reimbursement. 
                                    </p>
                                </div>
                            </div>
                            </div>
                            <div class="list-group-item">
                                <div class="card-header collapsed" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <h4>
                                    For Your Well-being
                                </h4>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                <div class="card-body">
                                    <h5 class="my-3">Personal Time Off and Holidays</h5>
                                    <i class="icofont icofont-beach-bed" aria-hidden=”true”></i>
                                        <p>To promote a healthy work-life balance, we offer a competitive PTO package. We also observe six scheduled holidays and provide four floating holidays for a total of 10 days a year that you get back to yourself.
                                        </p>
                                        <h5 class="my-3">24/7 Employee Assistance</h5>
                                        <i class="icofont icofont-live-support" aria-hidden=”true”></i>
                                        <p class="mb-0">
                                            We care about your health and well-being in every aspect. For that reason, we provide an Employee Assistance Program (EAP) that connects you to the best 24/7 counseling service to fit your individual needs, no matter what they may be. Emotional well-being, work issues, relationship issues, or financial issues, help us help you take care of yourself.
                                        </p>
                                </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="card-header collapsed" id="headingFive" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <h4>
                                    Just Because
                                </h4>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                <div class="card-body">
                                    <h5 class="my-3">Additional Benefits</h5>
                                    <p>
                                        The benefits named so far are just the baseline of what we offer to our employees. We have additional voluntary benefits including legal assistance, home, auto and pet insurance, and paid leave for military reservists to support both your work and your life. We’ve got you covered!
                                    </p>
                                    <h5 class="my-3">Events/Culture</h5>
                                    <i class="icofont icofont-pizza-slice" aria-hidden=”true”></i>
                                    <p>Benefits aside, we invest in our company culture to ensure our employees have a positive, fun experience both in and out of the office:</p> 
                                    <ul class="mb-0">
                                        <li>Lounge areas, outdoor seating, and a fully stocked break room for when you need a break.</li>
                                        <li>Holiday celebrations, family picnics, happy hour events, movie theatre outings, and much more.</li>
                                        <li>Regular Town Hall meetings to provide updates on the entire company.</li>
                                        <li>Maintaining relevant and current technologies in our office to support our mission.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="about" class="bg-yellow section-padding">
            <div class="about-bg-img d-none d-lg-block d-md-block" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-sm-12 col-md-8" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1500">
                        <div class="about-content">
                            <h3>Explore job opportunities with Enlighten</h3>
                            <p>
                                <strong>Why Enlighten?</strong> There are a lot of reasons our employees think Enlighten is a great place to work. At Enlighten, we know it takes more than great pay to continue attracting the best, most-talented people for the job. That’s why we strive to maintain a collaborative environment where our employees are respected and recognized for their contributions to our mission.
                            </p>
                            <p class="mb-4">
                                <strong>Enlighten is an equal opportunity employer.</strong> Reasonable accommodations will be provided to individuals with disabilities and disabled veterans who need assistance to apply. If assistance is needed please send a message to <a href="mailto:recruiting@eitccorp.com?subject=Careers">recruiting@eitccorp.com</a>. Veterans/Disabled welcome. U.S. citizenship or U.S. Person status required for most positions.
                            </p>
                            <button type="button"
                                @click="externalClick(jobsUrl)"
                                title="Explore job opportunities with Enlighten."
                                class="btn btn-light">
                                Explore jobs
                                <i class="icofont icofont-external-link ml-2"
                                    aria-hidden=”true”></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import analytics from '../analytics.js';

import AOS from 'aos'
import 'aos/dist/aos.css'

const $ = require('jquery');

export default {
    name: 'careers',
    data() {
        return {
            jobsUrl: 'https://jobs.eitccorp.com/jobs'
        };
    },
    mounted() {
        analytics.sendPageview();
        AOS.init();
    },
    methods: {
        externalClick(href) {
            analytics.record(href);
            window.open(href);
        }
    }
}
</script>

<style>
</style>
