<template>
    <!--MAIN BANNER AREA START -->
    <div class="banner-area">
        <div class="overlay"></div>
        <div class="full-screen">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-10 mx-auto">
                        <div class="banner-content screensaver">
                            <img src="../images/logo-white.png" alt="Enlighten" class="img-fluid w-logo">
                            <h4 class="text-white mt-4">
                                Engineering. Innovation. Mission.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 mx-auto text-white text-lg-center copyright">
            <small>
                © {{new Date().getFullYear()}} Enlighten, an HII Company.
            </small>
        </div>

        <video loop muted playsinline autobuffer autoplay
            poster="../images/bg-masthead.jpg" class="video-bkgd">
            <source src="media/nodes.mov" type="video/mp4">
        </video>
    </div>
</template>

<script>
import analytics from '../analytics.js';

export default {
    name: 'screensaver',
    mounted() {
        analytics.sendPageview();

        const body = document.querySelector('body');
        body.style.overflow = 'hidden';
    }
}
</script>

<style>
.screensaver .w-logo {
    height: 70px;
}
.copyright {
    position: relative;
    bottom: 2rem;
}
.banner-content p {
    margin-top: 2rem;
}
.banner-content .w-logo {
    animation: FlyByLogo ease 50s infinite;
    -webkit-animation: FlyByLogo ease 50s infinite;
    -moz-animation: FlyByLogo ease 50s infinite;
    -o-animation: FlyByLogo ease 50s infinite;
    -ms-animation: FlyByLogo ease 50s infinite;
}
@keyframes FlyByLogo {
    0% { transform: translateX(-150%); }
    3% { transform: translateX(0%); }
    95% { transform: translateX(0%); }
    100% { transform: translateX(600%); }
}
.banner-content h4 {
    animation: FlyByTagline ease 50s infinite;
    -webkit-animation: FlyByTagline ease 50s infinite;
    -moz-animation: FlyByTagline ease 50s infinite;
    -o-animation: FlyByTagline ease 50s infinite;
    -ms-animation: FlyByTagline ease 50s infinite;
}
@keyframes FlyByTagline {
    0% { transform: translateX(-100%); }
    3% { transform: translateX(0%); }
    95.5% { transform: translateX(0%); }
    100% { transform: translateX(300%); }
}
.copyright {
    animation: FlyByCopyright ease 50s infinite;
    -webkit-animation: FlyByCopyright ease 50s infinite;
    -moz-animation: FlyByCopyright ease 50s infinite;
    -o-animation: FlyByCopyright ease 50s infinite;
    -ms-animation: FlyByCopyright ease 50s infinite;
}
@keyframes FlyByCopyright {
    0% { transform: translateY(300%); }
    3% { transform: translateY(0%); }
    95% { transform: translateY(0%); }
    100% { transform: translateY(300%); }
}
</style>
