<template>
    <nav class="navbar navbar-expand-lg fixed-top trans-navigation">
        <div class="container">
            <a @click="sendClick('/')" class="navbar-brand" title="Home">
                <img src="../images/logo-white.png" alt="Enlighten" class="img-fluid w-logo">
                <img src="../images/logo-blk.png" alt="Enlighten" class="img-fluid b-logo">
            </a>
            <button class="navbar-toggler collapsed"
                type="button" data-toggle="collapse" data-target="#mainNav"
                aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
                <span class="sr-only">Toggle navigation</span>
            </button>

            <div class="collapse navbar-collapse justify-content-end" id="mainNav">
                <ul class="navbar-nav">
                    <li class="nav-item" :class="{'active': $route.name === 'about'}" 
                        @click="sendClick('/about')">
                        <a class="nav-link" title="Who we are">Who we are</a>
                    </li>
                    <li class="nav-item" :class="{'active': $route.name === 'expertise'}"
                        @click="sendClick('/expertise')">
                        <a class="nav-link" title="Our expertise">Our expertise</a>
                    </li>
                    <li class="nav-item" :class="{'active': $route.name === 'careers'}"
                        @click="sendClick('/careers')">
                        <a class="nav-link" title="Careers">Careers</a>
                    </li>
                    <li class="nav-item" :class="{'active': $route.name === 'contact'}"
                        @click="sendClick('/contact')">
                        <a class="nav-link" title="Contact us">Contact us</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import analytics from '../analytics.js';

import AOS from 'aos'
import 'aos/dist/aos.css'

const $ = require('jquery');

export default {
    mounted() {
        AOS.init();

        $(window).on('scroll', function () {
            if ($(window).scrollTop() > 70) {
                $('.site-navigation,.trans-navigation').addClass('header-white');
            } else {
                $('.site-navigation,.trans-navigation').removeClass('header-white');
            }
        });
    },
    methods: {
        sendClick(hash) {
            if (this.$router.currentRoute.path === hash) {
                return;
            }

            if (hash && hash !== '/') {
                analytics.sendPageview(hash);
            }

            this.$router.push(hash);
        }
    }
}
</script>
