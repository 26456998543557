<template>
    <div class="enlighten-container"
        :class="{'screensaver-container': $route.name === 'screensaver'}">
        <Nav v-show="$route.name !== 'screensaver' && $route.name !== 'oops'"></Nav>
        <router-view/>
        <Footer v-show="$route.name !== 'screensaver' && $route.name !== 'oops'"></Footer>
    </div>
</template>

<script>
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@icon/icofont/icofont.css';

import Footer from '@/components/Footer.vue';
import Nav from '@/components/Nav.vue';

import '@/css/style.css';

export default {
    components: {
        Footer,
        Nav
    }
}
</script>

<style>
.enlighten-container {
    overflow-x: hidden;
}

.screensaver-container {
    height: 100%;
    overflow-y: hidden;
}
</style>
