import Vue from 'vue';
import App from './App.vue';
import router from './router'
import analytics from './analytics.js';

window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false;

analytics.initialize();

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
