const RELATIVE_TO_ROOT = /^\//;

class Analytics {

    initialize() {
        window.GoogleAnalyticsObject = 'ga';
        window.ga = window.ga || function() {
            window.ga.q = window.ga.q || [];
            window.ga.q.push(arguments);
        };
        window.ga.l = 1 * new Date();

        window.ga('create', 'UA-125081225-1', 'auto');
    }

    send(fields) {
        window.ga('send', fields);
    }

    sendPageview(page) {
        let frag = page || `${location.pathname}${location.hash}`;

        if (!(RELATIVE_TO_ROOT).test(frag)) {
            frag = `/${frag}`;
        }

        return this.send({
            hitType: 'pageview',
            page: frag
        });
    }

    sendEvent(fields) {
        return this.send(Object.assign(
            {hitType: 'event'},
            fields
        ));
    }

    sendPerson(person) {
        return this.send({
            hitType: 'person',
            person: person
        });
    }

    record(href) {
        const promises = [];

        promises.push(this.sendEvent({
            eventCategory: 'External',
            eventAction: 'open',
            eventLabel: href
        }));

        return Promise.all(promises);
    }
}

const analytics = new Analytics();

export default analytics;
