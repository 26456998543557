import Vue from 'vue';
import Router from 'vue-router';

import Home from './views/Home.vue';
import About from './views/About.vue';
import Expertise from './views/Expertise.vue';
import Careers from './views/Careers.vue';
import Contact from './views/Contact.vue';
import PageNotFound from './views/PageNotFound.vue';
import Screensaver from './views/Screensaver.vue';

Vue.use(Router);

const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/expertise',
            name: 'expertise',
            component: Expertise
        },
        {
            path: '/careers',
            name: 'careers',
            component: Careers
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact
        },
        {
            path: '/screensaver',
            name: 'screensaver',
            alias: '/screen',
            component: Screensaver
        },
        { 
            path: '*',
            name: 'oops',
            component: PageNotFound
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router;
