<template>
    <section id="footer" class="section-padding bg-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="footer-widget">
                        <div class="d-inline-block bg-transparent">
                            <a title="An HII Company"
                                @click="externalClick(hiiUrl)">
                                <img src="../images/logo-hii.png" height="45" width="auto">
                            </a>
                        </div>
                        <div class="partner bg-transparent">
                            <a title="AWS Partner Network"
                                @click="externalClick(awsUrl)">
                                <img src="../images/aws-partner-network-color.png"
                                    height="36" width="auto">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="footer-link">
                        <div class="mb-2">
                            Engineering. Innovation. Mission.
                            <img src="../images/logo-icon.png" height="18" width="auto" style="padding-left: 10px;">
			</div>
                        <ul class="mb-0">
                            <li @click="sendClick('/')">
                                <a title="Home">Home</a>
                            </li>
                            <li @click="sendClick('/about')">
                                <a title="Who we are">Who we are</a>
                            </li>
                            <li @click="sendClick('/expertise')">
                                <a title="Our expertise">Our expertise</a>
                            </li>
                            <li @click="sendClick('/careers')">
                                <a title="Careers">Careers</a>
                            </li>
                            <li @click="sendClick('/contact')">
                                <a title="Contact us">Contact us</a>
                            </li>
                        </ul>
                        <ul class="h4 bg-transparent">
                            <li>
                                <a title="LinkedIn"
                                    @click="externalClick(linkedinUrl)">
                                    <i class="icofont icofont-linkedin"
                                        aria-hidden=”true”></i>
                                </a>
                            </li>
                            <li>
                                <a title="Twitter"
                                    @click="externalClick(twitterUrl)">
                                    <i class="icofont icofont-twitter"
                                        aria-hidden=”true”></i>
                                </a>
                            </li>
                            <li>
                                <a title="Facebook"
                                    @click="externalClick(fbUrl)">
                                    <i class="icofont icofont-facebook"
                                        aria-hidden=”true”></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copy text-center">
                        <p>
                            <small>
                                © {{new Date().getFullYear()}} Enlighten, an HII Company.
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import analytics from '../analytics.js';

export default {
    data() {
        return {
            awsUrl: 'https://partners.amazonaws.com/partners/001E0000012bPqEIAU/Enlighten%20IT%20Consulting',
            eitcUrl: 'https://www.eitccorp.com',
            fbUrl: 'https://www.facebook.com/Enlighten-IT-Consulting-1848808742032683/',
            hiiUrl: 'https://hii.com',
            linkedinUrl: 'https://www.linkedin.com/company/enlighten-it-group',
            twitterUrl: 'https://twitter.com/eitccorp?lang=en'
        };
    },
    methods: {
        sendClick(hash) {
            if (this.$router.currentRoute.path === hash) {
                return;
            }

            if (hash && hash !== '/') {
                analytics.sendPageview(hash);
            }

            this.$router.push(hash);
        },
        externalClick(href) {
            analytics.record(href);
            window.open(href);
        }
    }
}
</script>
