<template>
    <div>
        <div class="page-banner-area" id="page-banner">
            <div class="overlay overlay-sub"></div>
            <div class="container">
                <div class="row justify-content-center" data-aos="zoom-out" data-aos-duration="1500">
                    <div class="col-lg-10 m-auto text-center col-sm-12 col-md-12">
                        <div class="banner-content">
                            <h1 class="text-white">
                                Defending and supporting the nation's cybersecurity infrastructure
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <video loop muted playsinline autobuffer autoplay poster="../images/bg-masthead.jpg" class="video-bkgd">
                <source src="media/nodes.mov" type="video/mp4">
            </video>
        </div>
        <section class="section-padding bg-white">
            <div class="container">
                <div class="row justify-content-center" data-aos="fade-in" data-aos-delay="200" data-aos-duration="1500">
                    <div class="col-lg-12">
                        <h2 class="mb-4">Our expertise</h2>
                    </div>
                    <div class="col-lg-10 col-md-12 mb-xl-0 mb-lg-5 mx-auto">
                        <p>
                            At Enlighten, we focus on delivering capabilities that support our nation’s leading defense
                            initiatives. Our talented engineering, system administration, and program management teams are
                            responsible for big data solution development, deployment, and sustainment; all teams work
                            together to ensure these toolsets meet and exceed critical mission requirements. Take a look
                            below to explore the wide variety of advanced capabilities we support.
                        </p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6" data-aos="fade-in" data-aos-delay="600" data-aos-duration="1500">
                        <img src="../images/svg/website-creator-animate.svg" alt="Software &amp; Systems Engineering" class="img-fluid">
                    </div>
                    <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
                        <h3 class="mb-4">Software &amp; Systems Engineering</h3>
                        <h5>Agile and DevSecOps methodologies</h5>
                        <p>
                            Because of the increasingly sophisticated nature of our adversaries, we know our customers need
                            solutions quickly. Our teams work under a design, development, and delivery lifecycle that
                            prioritizes security and speed. We iterate over time and deploy only after solutions are
                            thoroughly tested, secure, and mission ready.
                        </p>
                        <h5>Full-stack development</h5>
                        <p>
                            Our team consists of both skilled frontend and backend developers who craft solutions and alter
                            them as the mission requires. No team works in a vacuum: we directly collaborate to integrate
                            toolsets and introduce beneficial user workflows.
                        </p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6 order-lg-12" data-aos="fade-in" data-aos-delay="600" data-aos-duration="1500">
                        <img src="../images/svg/cloud-hosting-animate.svg" alt="Cloud-based Services" class="img-fluid">
                    </div>
                    <div class="col-lg-6 order-lg-1" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
                        <h3 class="mb-4">Cloud-based Services</h3>
                        <h5>Cloud Architecture and Engineering</h5>
                        <p>
                            For years, Enlighten has developed and delivered operational big data capabilities and systems
                            with a focus on scalable, secure, and zero trust architecture principles. Our engineers
                            introduce infrastructure enhancements and manage the data lifecycle for capability adopters.
                        </p>
                        <h5>Amazon Web Services (AWS) Managed Service Provider</h5>
                        <p>
                            We leverage AWS GovCloud experience to build, deploy, and optimize your infrastructure for a
                            secure and cost-effective solution.
                        </p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6" data-aos="fade-in" data-aos-delay="1000" data-aos-duration="1500">
                        <img src="../images/svg/security-on-animate.svg" alt="Cyber Capabilities" class="img-fluid">
                    </div>
                    <div class="col-lg-6" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1500">
                        <h3 class="mb-4">Cyber Capabilities</h3>
                        <h5>Cyber Hunt</h5>
                        <p>
                            Enlighten builds innovative capabilities that enhance defensive cyber toolkits for operational
                            teams. These solutions are designed to improve collaboration and workflows, so analysts from
                            different teams can work together to defend our nation’s networks.
                        </p>
                        <h5>Situational Awareness</h5>
                        <p>
                            We design optimal interfaces for the most effective and user-friendly experience. Our customers
                            can apply the right visualization technique and sort through complex datasets to display
                            relevant mission data for informed decision making.
                        </p>
                        <h5>Analyst/Operator Training</h5>
                        <p>
                            We provide analysts with the tools and analytical methodologies to dig deep into data for
                            network monitoring and identification of malicious activity. Hands-on exercises simulate real
                            daily challenges and target mission activities.</p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6 order-lg-12" data-aos="fade-in" data-aos-delay="1200" data-aos-duration="1500">
                        <img src="../images/svg/setup-analytics-animate.svg" alt="Data Science" class="img-fluid">
                    </div>
                    <div class="col-lg-6 order-lg-1" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1500">
                        <h3 class="mb-4">Data Science</h3>
                        <h5>Analysis</h5>
                        <p>
                            Our data science teams work directly with customers to ensure they are getting the most out of
                            their data. Trustworthy and standardized data is a foundation for data scientists to have
                            confidence in their conclusions.
                        </p>
                        <h5>Artificial Intelligence &amp; Machine Learning</h5>
                        <p>
                            Our solutions can enhance your current functions and products by producing models that learn
                            from your data and provide predictive insights.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-padding bg-gray">
            <div class="overflow-hidden d-flex">
                <div class="img-ticker">
                    <img class="mx-x img-fluid" src="../images/tech/tech 1.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 2.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 3.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 4.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 5.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 6.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 7.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 8.png">

                    <img class="mx-x img-fluid" src="../images/tech/tech 1.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 2.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 3.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 4.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 5.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 6.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 7.png">
                    <img class="mx-x img-fluid" src="../images/tech/tech 8.png">
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import analytics from '../analytics.js';

import AOS from 'aos'
import 'aos/dist/aos.css'

const $ = require('jquery');

export default {
    name: 'expertise',
    mounted() {
        analytics.sendPageview();
        AOS.init();
    }
}
</script>

<style>

</style>
