<template>
    <div>
        <div class="page-banner-area" id="page-banner">
            <div class="overlay overlay-sub"></div>
            <div class="container">
                <div class="row justify-content-center" data-aos="zoom-out" data-aos-duration="1500">
                    <div class="col-lg-12 m-auto text-center col-sm-12 col-md-12">
                        <div class="banner-content">
                            <h1 class="text-white">
                                Where small business culture, large business resources, and cyber solutions meet
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <video loop muted playsinline autobuffer autoplay poster="../images/bg-masthead.jpg" class="video-bkgd">
                <source src="media/nodes.mov" type="video/mp4">
            </video>
        </div>
        <section class="callout bg-gray section-padding">
            <div class="callout-bg-img d-none d-lg-block d-md-block" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500"></div>
            <div class="container" data-aos="fade-in" data-aos-delay="200" data-aos-duration="1500">
                <div class="row">
                    <div class="col-lg-8 col-sm-12 col-md-8">
                        <h2 class="mb-3">Who we are</h2>
                        <p>
                            Enlighten’s operations prioritize secure development and product environments in response to the
                            growing number of cyber threats to government systems. To join the fight
                            against cyberattacks, we work under complex system development lifecycles to prevent network,
                            system, and information compromise and protect government operations. Our support for both the
                            customer and community at large is at the core of our mission and values, and we look
                            forward to continued innovation and proactivity in serving our customers.
                        </p>
                        <p>
                            We prioritize the tight-knit culture we have cultivated as a small business but recognize that
                            with a larger company comes expansive resources. Enlighten is a wholly owned subsidiary of
                            HII, creating the best of both worlds. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-padding bg-white vp-message">
            <div class="container">
                <div class="row" data-aos="fade-in" data-aos-delay="200" data-aos-duration="1500">
                    <div class="col-lg-3">
                        <img src="../images/Wagner-HR-199x200.png" alt="Steve Wagner, VP of Enlighten" class="img-fluid media-lg">
                    </div>
                    <div class="col-lg-9">
                        <div>
                            <p>
                                <img src="../images/Wagner-HR-199x200.png" alt="Steve Wagner, VP of Enlighten" class="media-sm">
                                Our success is a direct result of the careful collaboration and
                                exceptional contributions of our highly technical team. I confidently pass the baton to our
                                employees to challenge us and continue finding better approaches, share improvements, and
                                craft powerful cyber solutions. I look forward to what the future holds for Enlighten, and I
                                hope you’ll join us in the fight against cyber threats.
                            </p>
                            <h4>Steve Wagner</h4>
                            <p>VP of Enlighten</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-padding text-white bg-feature pb-0 pb-lg-5">
            <div class="container">
                <div class="row justify-content-center mb-5" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
                    <div class="col-lg-12">
                        <h2 class="mb-4 text-white">Our mission</h2>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12">
                        <ul>
                            <li>Delivering tailored, mission-specific, data-driven capabilities for our customers</li>
                            <li>Developing applications and analytic tools targeted towards distributed, cloud-based platforms</li>
                            <li>Analyzing data and results from our operational systems to report and recommend strategies to achieve customer goals</li>
                        </ul>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12">
                        <p>
                            We understand how critical it is to develop, deploy, and sustain security-based systems that
                            combat cyber threats in a timely manner. Our teams are ready to come in and save the day with
                            extensive experience in securing sensitive networks and data in both unclassified and classified
                            environments. The bottom line? We are ready and equipped to fight the good fight against cyber
                            threats.
                        </p>
                    </div>
                </div>
                <div class="row align-items-center who-we-serve-lg">
                    <div class="col-lg-4 col-sm-12 col-md-8 mb-4">
                        <ul class="circle-inner text-center rotate-circle-inner">
                            <li>
                                <div class="text-center rotate-icon-inner" style="position: relative;
                                                                                    top: 16px;
                                                                                    right: 3.5rem;
                                                                                    width: 16rem;">
                                    <h3 class="mb-4 text-white">Who we serve</h3>
                                    <p>Cyber warfighters, civilian analysts, and decision makers</p>
                                </div>
                            </li>
                            <li><img src="../images/icon/inner-1.png" class="rotate-icon-inner">
                            </li>
                            <li><img src="../images/icon/inner-2.png" class="rotate-icon-inner">
                            </li>
                            <li><img src="../images/icon/inner-3.png" class="rotate-icon-inner">
                            </li>
                            <li><img src="../images/icon/inner-4.png" class="rotate-icon-inner">
                            </li>
                            <li><img src="../images/icon/inner-5.png" class="rotate-icon-inner">
                            </li>
                            <li><img src="../images/icon/inner-6.png" class="rotate-icon-inner">
                            </li>
                        </ul>

                        <ul class="circle-outer text-center rotate-circle-outer">
                            <li> </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-1.png" alt="Program Management">
                                    <p>Program <br> Management</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-2.png" alt="Platform Engineering">
                                    <p>Platform <br> Engineering</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-3.png" alt="ITOps">
                                    <p>ITOps</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-4.png" alt="Technical Writing">
                                    <p>Technical <br> Writing</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-5.png" alt="Data Science">
                                    <p>Data <br> Science</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-6.png" alt="Quality Assurance">
                                    <p>Quality <br> Assurance</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-7.png" alt="DevOps">
                                    <p>DevOps</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-8.png" alt="Cloud Software Engineering">
                                    <p>Cloud Software <br> Engineering</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-9.png" alt="Cyber Analysis">
                                    <p>Cyber <br> Analysis</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-10.png" alt="UI/UX Design">
                                    <p>UI/UX <br> Design</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-11.png" alt="Front-end Engineering">
                                    <p>Front-end <br> Engineering</p>
                                </div>
                            </li>
                            <li>
                                <div class="rotate-icon-outer">
                                    <img src="../images/icon/outer-12.png" alt="Back-end Engineering">
                                    <p>Back-end <br> Engineering</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-4 offset-md-4" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1500">
                        <h2 class="mb-4 text-white">United in support of warfighters</h2>
                        <p>
                            Supporting our customers and the cyber community is at the core of our mission and values. We
                            are dedicated to providing superior, industry-leading customer service to the warfighters,
                            analysts, and decision makers who need us, and our employee base serves as the catalyst of our
                            success. No matter the task or problem to solve, our Enlighten team works together to meet and
                            exceed the needs of our customers.
                        </p>
                    </div>
                </div>
                <div class="row align-items-center who-we-serve-sm">
                    <div class="col-lg-12">
                        <h2 class="mb-4 text-white">Who we serve</h2>
                    </div>
                    <div class="col-lg-10 mx-auto mb-5">
                        <p>Cyber warfighters, civilian analysts, and decision makers</p>
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <img src="../images/icon/inner-1.png">
                            </li>
                            <li class="list-inline-item">
                                <img src="../images/icon/inner-2.png">
                            </li>
                            <li class="list-inline-item">
                                <img src="../images/icon/inner-3.png">
                            </li>
                            <li class="list-inline-item">
                                <img src="../images/icon/inner-4.png">
                            </li>
                            <li class="list-inline-item">
                                <img src="../images/icon/inner-5.png">
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-12">
                        <h2 class="mb-4 text-white">United in support of warfighters</h2>
                    </div>
                    <div class="col-lg-10 mx-auto">
                        <p>
                            Supporting our customers and the cyber community is at the core of our mission and values. We
                            are dedicated to providing superior, industry-leading customer service to the warfighters,
                            analysts, and decision makers who need us, and our employee base serves as the catalyst of our
                            success. No matter the task or problem to solve, our Enlighten team works together to meet and
                            exceed the needs of our customers.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section id="service-head" class="bg-feature pt-lg-0 pt-xl-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 m-auto pb-5 text-white" data-aos="fade-down" data-aos-delay="200" data-aos-duration="1500">
                        <h2 class="mb-4 text-white text-center">Divide and conquer</h2>
                        <p>
                            Our service capabilities cover cloud services, cyber, data science, and software and systems
                            engineering to provide big data management, tools, and security for our customers.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section id="service">
            <div class="container pb-4">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise4.png" alt="Software &amp; Systems Engineering">
                            </div>
                            <div class="service-inner">
                                <h3>Software &amp; Systems Engineering</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise1.png" alt="Cloud-based Services">
                            </div>
                            <div class="service-inner">
                                <h3>Cloud-based Services</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise2.png" alt="Cyber Capabilities">
                            </div>
                            <div class="service-inner">
                                <h3>Cyber Capabilities</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise3.png" alt="Data Science">
                            </div>
                            <div class="service-inner">
                                <h3>Data Science</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-lg-10 mx-auto">
                        <p>
                            We have worked hard over the years to earn the title “Cybersecurity Experts,” and we stop malicious
                            entities from impacting government networks. We focus on using all aspects of the system
                            development life cycle to deliver capabilities that support many of our nation’s leading defense
                            initiatives.
                        </p>
                        <a @click="sendClick('/expertise')" class="my-3">
                            Learn more
                            <i class="icofont icofont-simple-right ml-1"
                                aria-hidden=”true”></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import analytics from '../analytics.js';

import AOS from 'aos'
import 'aos/dist/aos.css'

const $ = require('jquery');

export default {
    name: 'about',
    mounted() {
        analytics.sendPageview();
        AOS.init();
        this.animateIcons();
    },
    methods: {
        sendClick(hash) {
            analytics.sendPageview(hash);
            this.$router.push(hash);
        },
        animateIcons() {
            /* ----------------------------------------------------------- */
            /*  Circular arrangement of icons
            /* ----------------------------------------------------------- */
            var type = 1, //circle type - 1 whole, 0.5 half, 0.25 quarter
                radius = '14em', //distance from center
                start = -60, //shift start from 0
                $elements = $('.circle-inner li:not(:first-child)'),
                numberOfElements = (type === 1) ? $elements.length : $elements.length - 1, //adj for even distro of elements when not full circle
                slice = 360 * type / numberOfElements;

            $elements.each(function (i) {
                var $self = $(this),
                    rotate = slice * i + start,
                    rotateReverse = rotate * -1;

                $self.css({
                    'transform': 'rotate(' + rotate + 'deg) translate(' + radius + ') rotate(' + rotateReverse + 'deg)'
                });
            });
            var type = 1, //circle type - 1 whole, 0.5 half, 0.25 quarter
                radius = '27em', //distance from center
                start = -90, //shift start from 0
                $elements = $('.circle-outer li:not(:first-child)'),
                numberOfElements = (type === 1) ? $elements.length : $elements.length - 1, //adj for even distro of elements when not full circle
                slice = 360 * type / numberOfElements;

            $elements.each(function (i) {
                var $self = $(this),
                    rotate = slice * i + start,
                    rotateReverse = rotate * -1;

                $self.css({
                    'transform': 'rotate(' + rotate + 'deg) translate(' + radius + ') rotate(' + rotateReverse + 'deg)'
                });
            });
        }
    }
}
</script>

<style>
</style>
